//This file makes an enquiry to the sever 
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const MonthDataContext = createContext();

function MonthDataContextProvider(props) {
    const [MonthData, setMonthData] = useState("");

    try {

        async function getMonthData() {
            //Get the current year and month
            const Year = new Date().getFullYear();
            const currentYear = { Year };
            const currentMonth = new Date().getMonth();
            //Send the post request to the sever to get the year's details
            const DataRes = await axios.post("http://approval.aamusted.edu.gh:8090/calender/MonthsDetails", currentYear);
            setMonthData(DataRes.data[currentMonth]);
            

        }




        useEffect(() => {
            getMonthData();
        }, [getMonthData]);




        return (
            <MonthDataContext.Provider value={{ MonthData, getMonthData }}>
                {props.children}
            </MonthDataContext.Provider>
        );
    } catch (err) {
        console.log(err);
    }

}
export default MonthDataContext;

export { MonthDataContextProvider };