//This file makes an enquiry to the sever (LoggedIn route form auth) to check if user is logged in or not
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const StaffContext = createContext();

function StaffContextProvider(props) {
    const [StaffData, setStaffData] = useState("");

    try {

        async function getStaffData() {
            const DataRes = await axios.get("http://approval.aamusted.edu.gh:8090/staff");
            setStaffData(DataRes.data.data);
           

        }




        useEffect(() => {
            getStaffData();
        }, []);




        return (
            <StaffContext.Provider value={{ StaffData, getStaffData }}>
                {props.children}
            </StaffContext.Provider>
        );
    } catch (err) {
        console.log(err);
    }

}
export default StaffContext;

export { StaffContextProvider };