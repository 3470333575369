//This file makes an enquiry to the sever (LoggedIn route form auth) to check if user is logged in or not
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const DataContext = createContext();

function DataContextProvider(props) {
    const [loggedInData, setLoggedInData] = useState("");

    try {

        async function getLoggedInData() {
            const DataRes = await axios.get("http://approval.aamusted.edu.gh:8090/auth/userDetails");
            setLoggedInData(DataRes.data);
           

        }




        useEffect(() => {
            getLoggedInData();
        }, []);




        return (
            <DataContext.Provider value={{ loggedInData, getLoggedInData }}>
                {props.children}
            </DataContext.Provider>
        );
    } catch (err) {
        console.log(err);
    }

}
export default DataContext;

export { DataContextProvider };