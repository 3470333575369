//This file makes an enquiry to the sever (LoggedIn route form auth) to check if user is logged in or not
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const HeadContext = createContext();

function HeadContextProvider(props) {
    const [headData, setHeadData] = useState("");

    try {

        async function getHeadData() {
            const DataRes = await axios.get("http://approval.aamusted.edu.gh:8090/auth/headID");
            setHeadData(DataRes.data);
            

        }




        useEffect(() => {
            getHeadData();
        }, []);




        return (
            <HeadContext.Provider value={{ headData, getHeadData }}>
                {props.children}
            </HeadContext.Provider>
        );
    } catch (err) {
        console.log(err);
    }

}
export default HeadContext;

export { HeadContextProvider };