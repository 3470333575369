export const ecobank = ['134 150', `
  

<svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100.000000pt" height="90.000000pt" viewBox="0 0 600.000000 388.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,388.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" style="fill:#fff;">
<path d="M3805 2930 c-66 -10 -151 -26 -190 -35 -79 -18 -337 -97 -332 -101 2 -2 64 12 138 30 254 64 371 80 599 80 162 0 220 -3 275 -17 205 -52 294 -146 303 -321 3 -68 -8 -147 -72 -507 -42 -234 -76 -431 -76 -437 0 -8 19 -12 54 -12 l54 0 21 113 c11 61 26 145 32 185 7 39 15 72 19 72 4 0 54 -83 111 -185 l104 -185 63 0 c34 0 62 2 62 5 0 3 -52 91 -116 196 l-117 191 169 169 169 169 -61 0 -60 0 -159 -170 c-87 -93 -161 -168 -162 -166 -2 2 17 120 43 262 54 302 54 348 5 449 -50 102 -146 168 -301 210 -101 27 -418 30 -575 5z"/>
<path d="M2849 2625 c-42 -25 -58 -42 -62 -62 -3 -15 -40 -225 -82 -466 -65 -371 -75 -441 -64 -453 25 -24 139 -47 214 -42 222 14 378 211 378 478 0 124 -36 201 -113 240 -56 29 -119 34 -190 16 -35 -9 -65 -14 -68 -12 -4 5 43 292 53 324 6 18 -4 14 -66 -23z m199 -365 c55 -34 76 -95 69 -204 -9 -158 -65 -288 -148 -343 -36 -23 -55 -28 -118 -31 -42 -1 -82 2 -90 7 -13 8 -8 50 34 287 l49 277 31 13 c47 21 134 17 173 -6z"/>
<path d="M1182 2524 c-12 -8 -26 -23 -31 -32 -15 -28 -152 -829 -145 -847 12 -29 52 -35 249 -35 209 0 205 -1 205 56 l0 24 -165 0 c-128 0 -165 3 -165 13 0 7 14 87 30 177 17 90 30 166 30 167 0 2 63 3 140 3 l140 0 0 24 c0 13 3 31 6 40 5 14 -10 16 -130 16 -124 0 -136 2 -136 18 0 10 11 82 25 160 14 78 25 145 25 147 0 3 70 5 155 5 l155 0 0 24 c0 13 3 31 6 40 5 14 -15 16 -183 16 -154 0 -193 -3 -211 -16z"/>
<path d="M1772 2329 c-141 -56 -241 -244 -242 -455 0 -127 36 -205 113 -246 56 -30 162 -30 229 0 46 20 47 21 38 54 -6 18 -10 34 -10 35 0 1 -16 -5 -35 -13 -45 -18 -108 -18 -146 2 -105 54 -87 366 29 501 52 60 167 85 220 47 26 -18 24 -19 46 23 l16 32 -32 17 c-52 27 -162 28 -226 3z"/>
<path d="M2263 2333 c-137 -48 -243 -247 -243 -458 0 -133 30 -200 110 -245 59 -32 162 -33 231 -3 136 60 224 222 236 433 8 148 -22 225 -107 268 -53 26 -160 29 -227 5z m181 -82 c52 -49 58 -173 16 -337 -39 -152 -103 -226 -197 -227 -96 -1 -129 47 -127 188 2 142 50 300 108 354 60 56 153 66 200 22z"/>
<path d="M3447 2328 c-68 -21 -69 -22 -63 -52 4 -16 8 -32 11 -34 2 -2 23 3 47 13 24 9 75 19 113 22 120 8 172 -37 159 -137 -5 -41 -11 -51 -28 -54 -11 -3 -55 -10 -96 -16 -226 -37 -338 -135 -340 -296 0 -67 29 -111 95 -144 44 -22 62 -24 155 -23 76 1 123 7 171 22 l65 21 42 242 c23 134 42 265 42 291 0 76 -39 132 -109 156 -55 18 -187 12 -264 -11z m214 -471 c-16 -87 -33 -163 -39 -169 -6 -6 -39 -13 -75 -15 -135 -11 -187 19 -187 109 1 95 43 158 134 199 50 22 189 51 194 40 1 -4 -11 -77 -27 -164z"/>
<path d="M4070 2335 c-71 -16 -120 -34 -120 -44 0 -3 -27 -156 -60 -340 -33 -184 -60 -336 -60 -338 0 -2 26 -3 58 -1 l57 3 55 310 c31 171 59 315 63 322 11 18 114 36 165 28 62 -9 102 -49 102 -102 0 -22 -20 -155 -45 -297 -25 -142 -44 -260 -43 -261 2 -1 28 0 58 2 l56 5 47 272 c26 149 47 281 47 293 0 51 -32 103 -78 131 -40 23 -59 27 -142 29 -56 1 -121 -3 -160 -12z"/>
<path d="M1145 1258 c-11 -48 -55 -307 -55 -322 0 -9 6 -16 14 -16 10 0 18 19 25 55 6 30 16 80 22 110 11 58 28 72 75 61 29 -8 31 -38 10 -153 -12 -68 -12 -73 5 -73 19 0 35 55 46 161 5 53 4 58 -21 74 -19 13 -37 16 -65 11 l-38 -6 8 43 c4 23 8 50 9 60 0 25 -29 21 -35 -5z"/>
<path d="M2879 1251 c-17 -17 -29 -40 -29 -55 0 -16 -6 -26 -15 -26 -17 0 -21 -26 -5 -32 6 -2 3 -44 -9 -110 -17 -103 -17 -108 0 -108 20 0 23 9 39 110 16 102 19 110 41 110 10 0 19 7 19 15 0 8 -9 15 -19 15 -17 0 -18 4 -10 32 5 18 21 42 36 55 28 22 28 22 5 23 -13 0 -37 -13 -53 -29z"/>
<path d="M4722 1103 c-17 -97 -30 -179 -27 -181 2 -3 11 -2 19 1 9 4 17 27 21 61 4 31 10 56 14 56 3 0 21 -27 39 -60 26 -48 38 -60 58 -60 24 0 24 0 -17 66 l-42 66 52 52 c54 54 60 66 37 66 -8 0 -38 -27 -67 -60 -29 -33 -54 -59 -55 -57 -3 3 20 153 31 205 4 17 1 22 -13 22 -16 0 -23 -23 -50 -177z"/>
<path d="M3147 1254 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28 15z"/>
<path d="M920 1225 c0 -10 10 -15 30 -15 17 0 30 -5 30 -12 0 -7 -11 -73 -24 -145 -22 -128 -23 -133 -5 -133 21 0 22 4 44 140 26 160 22 150 61 150 24 0 34 5 34 15 0 12 -16 15 -85 15 -69 0 -85 -3 -85 -15z"/>
<path d="M1768 1234 c-20 -6 -20 -5 -48 -166 -25 -143 -25 -150 -3 -146 11 2 20 17 25 43 17 86 17 85 49 85 52 0 98 26 114 64 31 75 7 117 -71 122 -27 1 -57 0 -66 -2z m108 -39 c19 -29 12 -75 -15 -99 -10 -9 -35 -20 -56 -23 l-38 -6 7 52 c11 83 21 101 56 101 20 0 34 -8 46 -25z"/>
<path d="M2587 1085 c-42 -85 -77 -157 -77 -160 0 -3 6 -5 13 -5 8 0 24 23 37 50 l23 50 57 0 57 0 7 -50 c6 -42 10 -50 27 -50 17 0 20 5 15 28 -3 15 -12 72 -21 127 -25 166 -25 165 -43 165 -12 0 -40 -45 -95 -155z m102 -17 c1 -27 -1 -28 -44 -28 -25 0 -45 2 -45 5 0 3 17 38 37 78 l37 72 7 -50 c4 -27 8 -62 8 -77z"/>
<path d="M4048 1234 c-20 -6 -21 -8 -48 -154 -26 -135 -25 -146 3 -154 76 -20 150 5 173 60 15 37 7 67 -24 90 -12 9 -9 15 17 41 33 34 39 68 17 98 -13 18 -99 30 -138 19z m103 -35 c21 -21 19 -51 -6 -77 -15 -16 -87 -34 -97 -24 -10 10 10 105 25 114 22 14 57 8 78 -13z m-13 -146 c18 -24 8 -73 -19 -95 -13 -11 -38 -18 -61 -18 l-41 0 7 62 c4 34 10 66 13 71 9 15 86 0 101 -20z"/>
<path d="M3017 1170 c-20 -4 -39 -9 -40 -11 -5 -6 -37 -191 -37 -212 0 -25 27 -21 33 6 3 12 11 56 18 97 14 85 22 100 54 100 13 0 25 7 29 15 6 16 1 17 -57 5z"/>
<path d="M1385 1148 c-36 -28 -55 -76 -55 -138 0 -66 23 -90 84 -90 49 0 76 7 76 21 0 4 -23 7 -51 6 -40 -2 -54 2 -65 17 -12 17 -20 64 -11 67 1 0 36 6 77 12 l75 12 3 37 c6 71 -72 104 -133 56z m86 -8 c19 -11 27 -64 12 -73 -5 -3 -34 -8 -66 -12 l-58 -7 16 39 c24 58 54 75 96 53z"/>
<path d="M1983 1163 c-29 -11 -12 -25 22 -19 52 10 75 0 75 -33 0 -26 -4 -29 -42 -35 -58 -9 -96 -35 -109 -73 -13 -40 1 -70 37 -79 29 -8 103 1 115 13 4 4 15 48 23 97 14 80 14 92 1 113 -13 19 -24 23 -63 22 -26 0 -53 -3 -59 -6z m83 -155 c-9 -64 -14 -70 -56 -66 -86 8 -50 116 39 118 23 0 23 -2 17 -52z"/>
<path d="M2208 1163 c-10 -2 -18 -5 -18 -6 0 -1 -9 -49 -20 -107 -24 -126 -24 -130 -5 -130 16 0 17 4 40 130 16 86 24 100 62 100 17 0 53 -23 53 -33 0 -1 -7 -37 -15 -80 -20 -103 -19 -117 3 -117 16 0 22 16 36 101 15 92 15 104 1 125 -13 21 -23 24 -68 23 -28 -1 -60 -3 -69 -6z"/>
<path d="M3126 1162 c-2 -4 -12 -50 -20 -102 -9 -52 -19 -105 -21 -117 -4 -18 -1 -23 13 -23 16 0 21 15 36 108 10 59 20 115 22 125 5 17 -20 25 -30 9z"/>
<path d="M3257 1150 c-39 -31 -58 -74 -59 -130 0 -58 14 -88 46 -96 34 -8 69 -1 74 15 3 10 -2 12 -20 8 -15 -4 -33 0 -47 10 -21 14 -23 22 -19 70 6 74 32 107 87 111 51 4 51 26 0 30 -24 2 -44 -4 -62 -18z"/>
<path d="M3427 1163 c-33 -9 -5 -23 34 -17 45 8 69 -7 69 -42 0 -16 -6 -24 -17 -24 -73 0 -143 -49 -143 -99 0 -44 36 -65 100 -59 28 3 53 8 56 11 8 8 34 145 34 179 0 39 -24 58 -73 57 -23 -1 -50 -3 -60 -6z m88 -145 c-4 -24 -11 -51 -17 -60 -19 -37 -108 -9 -94 29 3 8 6 19 6 24 0 14 62 49 88 49 22 0 23 -3 17 -42z"/>
<path d="M3648 1158 c-10 -8 -23 -54 -35 -125 -20 -110 -20 -113 -2 -113 11 0 19 3 19 6 0 29 35 202 42 212 14 17 63 15 78 -4 11 -12 11 -30 1 -92 -6 -42 -14 -87 -17 -99 -5 -18 -2 -23 14 -23 23 0 26 8 41 94 17 92 14 134 -8 146 -27 14 -114 13 -133 -2z"/>
<path d="M4283 1163 c-27 -10 -12 -24 21 -18 51 10 76 -1 76 -34 0 -26 -4 -29 -42 -35 -56 -9 -95 -34 -108 -69 -14 -40 -13 -44 10 -67 22 -22 80 -27 123 -11 20 8 26 20 38 88 17 98 16 123 -7 140 -17 13 -86 17 -111 6z m83 -155 c-9 -64 -14 -70 -56 -66 -86 8 -50 116 39 118 23 0 23 -2 17 -52z"/>
<path d="M4508 1163 c-10 -2 -18 -8 -18 -12 0 -4 -9 -57 -20 -116 -10 -60 -17 -111 -15 -113 2 -3 10 -2 18 1 8 3 20 45 31 108 10 57 23 107 30 111 19 13 63 9 76 -7 11 -13 10 -33 -3 -108 -21 -114 -21 -107 3 -107 11 0 20 1 20 3 0 1 7 40 15 87 19 107 19 125 -1 144 -15 15 -93 20 -136 9z"/>
</g>
</svg>

`]