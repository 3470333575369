export const sygnet = ['608 134', `
  <title>AAMUSTED LOGO</title>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="300.000000pt" height="100.000000pt" viewBox="0 0 4713.000000 618.000000" preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,618.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none" style="fill:#80d0ff;">
<path d="M2540 6145 c-145 -30 -247 -84 -356 -188 -120 -114 -227 -286 -304 -487 -18 -47 -79 -200 -135 -340 -139 -345 -1576 -4005 -1630 -4150 -87 -235 -100 -292 -100 -430 0 -122 1 -127 34 -195 48 -100 187 -237 291 -287 102 -49 167 -62 291 -56 220 11 322 84 437 313 62 124 106 235 297 738 l178 467 1208 0 c1141 0 1209 -1 1218 -17 5 -10 77 -193 160 -408 223 -573 264 -668 364 -840 103 -177 242 -254 457 -255 154 0 283 49 395 152 68 63 141 165 169 238 24 62 31 174 17 267 -15 94 -77 288 -138 432 -24 58 -392 970 -818 2026 -903 2242 -1002 2484 -1057 2579 -193 334 -404 458 -776 455 -76 0 -166 -7 -202 -14z m664 -2502 c240 -656 436 -1197 436 -1203 0 -7 -293 -10 -885 -10 -760 0 -885 2 -885 14 0 11 833 2353 866 2436 5 11 10 7 19 -15 7 -16 209 -567 449 -1222z"/>
<path d="M8558 6150 c-331 -56 -528 -269 -727 -788 -38 -97 -83 -211 -100 -252 -38 -87 -1527 -3875 -1606 -4085 -29 -77 -68 -189 -86 -250 -30 -97 -34 -123 -34 -225 0 -113 1 -117 38 -195 70 -147 242 -288 403 -330 128 -33 316 -11 415 48 114 69 211 238 363 637 47 124 136 358 198 520 l113 295 1211 3 1211 2 138 -352 c76 -194 151 -387 166 -428 94 -258 232 -521 315 -606 88 -88 213 -134 364 -134 177 0 288 48 421 180 67 66 95 102 125 164 34 70 39 88 42 171 7 157 -31 307 -153 600 -34 82 -405 1001 -823 2040 -1049 2604 -995 2474 -1097 2630 -171 260 -381 366 -715 364 -74 -1 -156 -5 -182 -9z m627 -2474 c241 -660 441 -1210 443 -1223 l4 -23 -886 0 c-580 0 -886 3 -886 10 0 5 196 557 435 1226 239 668 435 1218 435 1222 0 28 71 -162 455 -1212z"/>
<path d="M19807 6145 c-174 -44 -315 -168 -376 -329 -58 -155 -55 -63 -59 -1726 -3 -1549 1 -1828 34 -2111 32 -275 94 -554 160 -722 78 -198 216 -426 348 -575 410 -464 1127 -689 2131 -669 284 5 432 20 648 63 440 87 765 251 1059 533 409 391 571 811 618 1596 6 102 10 802 10 1750 0 1691 0 1686 -50 1829 -56 163 -175 284 -338 344 -83 30 -257 41 -354 23 -227 -44 -384 -215 -446 -486 -14 -62 -16 -270 -22 -1795 -5 -1532 -8 -1736 -23 -1825 -24 -151 -43 -232 -78 -341 -165 -516 -517 -736 -1184 -737 -419 0 -676 70 -890 244 -179 145 -292 349 -355 637 -55 251 -53 182 -60 2042 -5 1536 -8 1732 -22 1790 -59 239 -176 380 -374 451 -78 28 -290 36 -377 14z"/>
<path d="M27465 6149 c-429 -35 -815 -151 -1114 -333 -134 -81 -203 -136 -311 -246 -208 -210 -342 -461 -407 -760 -15 -69 -18 -129 -18 -325 1 -266 13 -353 76 -532 142 -403 489 -724 1013 -933 281 -112 611 -213 986 -300 563 -131 842 -229 1026 -359 222 -156 315 -331 315 -591 0 -129 -21 -232 -67 -322 -130 -260 -416 -455 -772 -528 -280 -56 -632 -44 -857 32 -146 48 -347 176 -436 276 -101 113 -217 318 -329 582 -73 174 -131 269 -202 336 -103 97 -198 134 -348 134 -216 0 -387 -106 -483 -299 -41 -82 -41 -85 -45 -210 -9 -266 82 -537 277 -827 378 -564 936 -856 1769 -925 179 -15 631 -6 791 15 344 46 612 121 856 241 243 119 478 295 616 461 302 362 431 744 416 1229 -10 316 -64 533 -184 740 -281 486 -760 754 -1813 1015 -931 232 -1146 315 -1351 523 -175 178 -206 429 -78 642 71 118 225 248 381 319 171 79 334 109 588 109 267 1 455 -34 618 -115 181 -89 361 -308 527 -638 54 -108 149 -242 211 -299 75 -68 142 -92 274 -99 187 -8 293 31 403 148 116 122 156 215 164 380 6 123 -11 233 -58 368 -70 199 -180 364 -357 533 -342 327 -774 505 -1357 559 -127 12 -574 11 -720 -1z"/>
<path d="M12890 6049 c-82 -13 -228 -61 -288 -96 -100 -59 -162 -150 -205 -302 l-22 -76 -3 -2465 c-2 -1781 0 -2493 8 -2565 23 -200 88 -337 202 -421 122 -90 221 -120 375 -111 113 6 191 30 272 83 117 78 179 172 223 335 l23 84 3 2140 4 2140 484 -1925 c604 -2404 585 -2334 681 -2509 92 -166 213 -271 373 -323 92 -29 278 -36 391 -14 239 47 412 215 502 488 44 132 83 283 594 2308 l498 1975 6 -2140 c5 -1950 6 -2146 22 -2206 43 -173 101 -265 217 -344 107 -73 168 -90 315 -90 139 0 213 20 305 82 119 80 180 173 222 339 l23 89 0 2515 c0 2356 -1 2520 -17 2590 -58 249 -221 379 -523 420 -141 19 -866 8 -965 -14 -165 -37 -261 -107 -320 -232 -93 -197 -69 -113 -611 -2151 -238 -898 -436 -1633 -439 -1633 -3 0 -194 717 -424 1593 -439 1669 -479 1817 -546 2012 -77 223 -138 307 -263 365 -125 58 -171 63 -632 66 -236 1 -449 -2 -485 -7z"/>
<path d="M31065 6043 c-213 -45 -357 -192 -385 -393 -36 -252 88 -466 312 -539 122 -39 162 -41 895 -41 l713 0 3 -2242 3 -2243 21 -93 c72 -312 277 -481 584 -482 289 0 491 162 571 460 l23 85 2 2257 3 2257 748 3 c821 4 787 1 927 67 171 80 267 257 252 466 -8 104 -38 191 -93 265 -73 99 -156 146 -312 176 -113 22 -4164 19 -4267 -3z"/>
<path d="M36955 6045 c-299 -55 -446 -228 -485 -570 -14 -124 -14 -4656 0 -4780 21 -182 83 -340 168 -422 52 -51 158 -105 267 -135 l80 -23 1807 -3 c1986 -3 1880 -6 2016 58 79 37 166 123 205 202 39 82 54 188 39 285 -30 188 -132 308 -317 371 l-80 27 -1492 3 -1493 2 0 855 0 855 1343 2 c1272 3 1345 4 1397 22 186 62 275 165 302 354 31 209 -52 377 -226 462 -125 62 -75 60 -1508 60 l-1308 0 0 735 0 735 1414 0 c1558 0 1506 -2 1636 62 312 153 317 657 7 798 -139 63 -43 60 -1959 59 -1379 -1 -1758 -4 -1813 -14z"/>
<path d="M42530 6045 c-307 -54 -445 -211 -490 -555 -8 -60 -10 -770 -7 -2440 4 -2532 1 -2400 52 -2577 34 -118 129 -225 258 -288 146 -72 99 -69 1217 -73 1220 -4 1399 5 1720 84 581 144 1015 449 1345 944 95 142 240 435 295 595 150 433 228 1064 200 1605 -29 574 -126 990 -325 1395 -158 323 -321 537 -594 777 -357 314 -686 451 -1256 524 -113 15 -274 17 -1235 20 -881 2 -1120 0 -1180 -11z m1755 -935 c352 -21 655 -83 830 -170 124 -61 209 -125 314 -235 144 -153 271 -371 340 -585 158 -488 176 -1304 41 -1865 -118 -494 -373 -870 -700 -1035 -102 -52 -242 -98 -378 -127 l-127 -26 -682 -5 -683 -4 0 2031 0 2031 438 0 c240 0 514 -5 607 -10z"/>
</g>
</svg>
`]

