import React from "react";
import Router from "./Routez";
// import NotFound from "./Nf";
import axios from "axios";
import { AuthContextProvider } from "./context/AuthContext";
import { DataContextProvider } from "./context/DataContext";
import { MonthDataContextProvider } from "./context/MonthDataContext";
import { StaffContextProvider } from "./context/StaffContext";
import { HeadContextProvider } from "./context/HeadContext";
import { HeadDetailsContextProvider } from "./context/HeadDetailsContext";
// const { headID } = useContext(HeadContext);

import "antd/dist/antd.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Redux
import { Provider } from 'react-redux'
import store from './store'
// import store from "./reduxContainer/store";
// import Alert from "./containers/Alert";

axios.defaults.withCredentials = true;



const App = () => (
  <Provider store={store}>
    <AuthContextProvider>
      <DataContextProvider>
        <MonthDataContextProvider>
          <StaffContextProvider>
            <HeadContextProvider>
              <HeadDetailsContextProvider>
                
                <Router />
                {/* <NotFound /> */}
              </HeadDetailsContextProvider>
            </HeadContextProvider>
          </StaffContextProvider>
        </MonthDataContextProvider>
      </DataContextProvider>
    </AuthContextProvider>
  </Provider>
);

export default App;
