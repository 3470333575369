import React, { useContext } from 'react';
import { Switch } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import Route from 'react-router-dom/Route'
import axios from "axios";
import './scss/style.scss';
import AuthContext from "./context/AuthContext"
import HeadContext from "./context/HeadContext"
// import { Redirect, useHistory } from 'react-router-dom';


axios.defaults.withCredentials = true;

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const UserLayout = React.lazy(() => import('./containers/UserLayout'));
const HeadLayout = React.lazy(() => import('./containers/HeadLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Register1 = React.lazy(() => import('./views/pages/register/Register1'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
// const GetStaff = React.lazy(() => import('./views/approveslip/GetStaff'));






function Routez() {


  const { loggedIn } = useContext(AuthContext);
  const { headData } = useContext(HeadContext);






  return (

    <Router>
      {/* <HashRouter> */}
      <React.Suspense fallback={loading}>

        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />


          {loggedIn === true && headData === 1 && (<>
            <Route path="/headdashboard" name="UserHome" render={props => <HeadLayout {...props} />} />

            <Route path="/headviewslip" name="Viewslip" render={props => < HeadLayout {...props} />} />

            <Route path="/headsetapprovals" name="Setapprovals" render={props => < HeadLayout {...props} />} />
            {/* <Route path name="UserHome"  Link to="headdashboard" /> */}
            <Route exact path>
              <Redirect to="/headdashboard" />
            </Route>



          </>)
          }

          {/* render={props => <HeadLayout {...props} />} */}
          {loggedIn === true && headData === 2 && (<>

            {/* <Route name="Home" component={TheLayout} render={props => <TheLayout {...props} />} /> */}
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/register1" name="Register Page" render={props => <Register1 {...props} />} />
            <Route path="/admindashboard" name="Home" render={props => <TheLayout {...props} />} />
            <Route path="/dashboard" name="UserHome" render={props => <UserLayout {...props} />} />
            <Route path="/headdashboard" name="UserHome" render={props => <HeadLayout {...props} />} />
            <Route path="/adminviewslip" name="Viewslip" render={props => < TheLayout {...props} />} />
            <Route path="/userviewslip" name="UserViewslip" render={props => < UserLayout {...props} />} />
            <Route path="/headviewslip" name="Viewslip" render={props => < HeadLayout {...props} />} />
            <Route path="/users" name="User" render={props => < TheLayout {...props} />} />
            <Route path="/payprocessor" name="Payprocessor" render={props => < TheLayout {...props} />} />
            <Route path="/departmenthead" name="Departmenthead" render={props => < TheLayout {...props} />} />
            <Route path="/payslipnotifications" name="Payslipnotifications" render={props => < TheLayout {...props} />} />
            <Route path="/payslipquerrygenerator" name="Payslipquerrygenerator" render={props => < TheLayout {...props} />} />
            <Route path="/payslipsettings" name="Payslipsettings" render={props => < TheLayout {...props} />} />
            <Route path="/setapprovals" name="Setapprovals" render={props => < TheLayout {...props} />} />
            <Route path="/headsetapprovals" name="Setapprovals" render={props => < HeadLayout {...props} />} />



            <Route exact path="/">
              <Redirect to="/admindashboard" />
            </Route>

          </>)}










          {loggedIn === true && headData === 0 && (<>
            {/* <Route name="Home" component={TheLayout} render={props => <TheLayout {...props} />} /> */}
            <Route path="/dashboard" name="UserHome" render={props => <UserLayout {...props} />} />
            <Route path="/viewslip" name="UserViewslip" render={props => < UserLayout {...props} />} />
            <Route exact path>
              <Redirect to="/dashboard" />
            </Route>
          </>)}

          {loggedIn === true && headData === "" && (<>
            {/* <Route name="Login Page" render={props => <Login {...props} />} /> */}
            {/* <Route exact path="" name="Login Page" <Redirect to="/login" /> */}
            <Route path="/departmenthead" name="Departmenthead" render={props => < UserLayout {...props} />} />

            <Route path="/dashboard" name="UserHome" render={props => <UserLayout {...props} />} />
            <Route path="/viewslip" name="UserViewslip" render={props => < UserLayout {...props} />} />
            <Route exact path>
              <Redirect to="/dashboard" />
            </Route>
          </>)}







          {loggedIn === false && <Redirect to="/login" />}
          <Route exact path="/" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />

          {/* <Route exact path="/GetStaff" name="Get Staff" render={props => <GetStaff {...props} />} />  */}

          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />

          {/* <Route name="Page 404" render={props => <Page404 {...props}/>} /> */}
        </Switch>

      </React.Suspense>
      {/* </HashRouter> */}
    </Router>

  );

}

export default Routez