//This file makes an enquiry to the sever to get details of logged in head
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const HeadDetailsContext = createContext();

function HeadDetailsContextProvider(props) {
    const [headDetails, setHeadDetails] = useState("");

    try {

        async function getHeadDetails() {
            const DataRes = await axios.get("http://approval.aamusted.edu.gh:8090/auth/headDetails");
            setHeadDetails(DataRes.data);
            

        }




        useEffect(() => {
            getHeadDetails();
        }, []);




        return (
            <HeadDetailsContext.Provider value={{ headDetails, getHeadDetails }}>
                {props.children}
            </HeadDetailsContext.Provider>
        );
    } catch (err) {
        console.log(err);
    }

}
export default HeadDetailsContext;

export { HeadDetailsContextProvider };