//This file makes an enquiry to the sever (LoggedIn route form auth) to check if user is logged in or not
import React, { createContext, useEffect, useState } from "react";
import axios from "axios";

const AuthContext = createContext();

function AuthContextProvider(props) {
    
    const [loggedIn, setLoggedIn] = useState("");
    try{

    async function getLoggedIn() {
        const loggedInRes = await axios.get("http://approval.aamusted.edu.gh:8090/auth/loggedIn");
        setLoggedIn(loggedInRes.data);
        
    }

    useEffect(() => {
        getLoggedIn();
    }, []);




    return (
    <AuthContext.Provider value={{ loggedIn, getLoggedIn }}>
        {props.children}
    </AuthContext.Provider>
    );
    }catch(err){
        console.log(err);

    }
}
export default AuthContext;

export { AuthContextProvider };